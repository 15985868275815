/* General container styling */
.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 20px;
  width: 100%; /* Ensure it fits the screen width */
  box-sizing: border-box;
}

/* Logo container styling */
.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.logo {
  max-width: 120px;
  height: auto;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
}

/* Content area styling */
.container-item {
  flex-grow: 1;
  text-align: center;
  font-size: 1rem;
}

/* DMC logo positioning */
.dmc-logo {
  max-width: 120px;
  height: auto;
  margin-left: auto;
}

/* Heading styling */
.heading {
  color: orange;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
}

/* ✅ Improved Responsive Styling */
@media (max-width: 1200px) {
  .container {
    gap: 15px;
    padding: 8px;
  }

  .logo {
    max-width: 100px;
  }

  .logo-text {
    font-size: 1.4rem;
  }

  .dmc-logo {
    max-width: 100px;
  }

  .heading {
    font-size: 1.6rem;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center;
  }

  .logo-container {
    justify-content: center;
  }

  .container-item {
    width: 100%; /* Make content take full width */
    margin-top: 10px;
  }

  .dmc-logo {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }

  .logo-container {
    justify-content: center;
    gap: 5px;
  }

  .logo {
    max-width: 80px;
  }

  .logo-text {
    font-size: 1.2rem;
  }

  .dmc-logo {
    max-width: 80px;
  }

  .heading {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 5px;
    gap: 8px;
  }

  .logo {
    max-width: 60px;
  }

  .logo-text {
    font-size: 1rem;
  }

  .dmc-logo {
    max-width: 60px;
  }

  .heading {
    font-size: 1.2rem;
  }
}
