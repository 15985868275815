/* Container for notifications */
.notifications-box {
  width: 100%;
  /* background-color: #f4f4f9; */
  padding: 10px 0;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 8px;
}

/* Scrolling container */
.notifications-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Scrolling effect */
.notification-scroll {
  display: block;
  gap: 30px;
  animation: scroll-up 20s linear infinite;
}

/* Notification item */
.notification {
  white-space: nowrap;
  padding: 8px 16px;
  /* background-color: #e0e7ff; */
  color: black;
  font-size: 16px;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.notification a {
  color:  #2e0669;;
  text-decoration: none;
}

.notification:hover {
  /* background-color: #c7d2fe; */
}

/* Scroll animation */
@keyframes scroll-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .notification {
    font-size: 14px;
    padding: 6px 12px;
  }

  .notification-scroll {
    gap: 20px;
    animation-duration: 12s;
  }
}
