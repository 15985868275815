/* General Styling */
.topnav {
  background-image: linear-gradient(to right, #555555, #A9A9A9);
  text-align: center;
  font-size: 20px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

/* Remove default button styles */
button {
  border: none;
  background: transparent;
  cursor: pointer;
}

/* Icon Styling */
.digitali-icon {
  color: white;
  font-size: 24px;
  margin-right: 8px;
}

/* Navbar Link Styling */
.topnav a {
  display: inline-block;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.topnav a:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
}

.active {
  color: #ffffff;
  font-weight: bold;
}

/* Dropdown Button */
.dropbtn {
  color: white;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s ease;
}

.dropbtn:hover {
  background-color: #444444;
  color: #ffffff;
}

/* Dropdown Wrapper */
.dropdown {
  position: relative;
  display: inline-block;
}

.drop-icon {
  height: 24px;
  width: 24px;
}

/* Dropdown Content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #555555;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  width: 250px;
  overflow: hidden;
  border-radius: 4px;
}

.dropdown-content button {
  background-color: transparent;
  color: #ffffff;
  padding: 12px;
  text-align: left;
  width: 100%;
  border: none;
  transition: background-color 0.2s ease;
}

.dropdown-content button:hover {
  background-color: #444444;
  color: #ffffff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Link Button */
.link-btn {
  text-align: left;
  color: #ffffff;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  display: block;
}

.link-btn:hover {
  background-color: #444444;
  color: #ffffff;
}

/* Responsive Design */
.menu-icon {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  /* Show menu icon on smaller screens */
  .menu-icon {
    display: flex;
    align-items: center;
  }

  .menu-off-options {
    display: none;
  }

  .menu-on-options {
    display: flex;
    flex-direction: column;
    background-color: #555555;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 10;
  }

  .topnav {
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    padding: 10px;
  }

  .topnav a,
  .dropbtn {
    padding: 12px;
    font-size: 14px;
  }

  .dropdown-content {
    position: static;
    width: 100%;
    box-shadow: none;
    background-color: #444444;
  }

  .dropdown-content button {
    padding: 10px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .topnav {
    font-size: 14px;
    padding: 8px;
  }

  .dropbtn {
    font-size: 14px;
    padding: 8px;
  }

  .dropdown-content button {
    font-size: 14px;
  }
}
